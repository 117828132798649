import logo1 from '../../../Assets/Logo Wall/1200px-Dominos_pizza_logo.svg.png';
import logo2 from '../../../Assets/Logo Wall/1200px-Swiggy_logo.svg.png';
import logo3 from '../../../Assets/Logo Wall/20210806_plum_skin_hair_maekup.png';
import logo4 from '../../../Assets/Logo Wall/2560px-Amazon_logo.svg.png';
import logo5 from '../../../Assets/Logo Wall/269-2690220_shubo-durga-puja-inorbit-mall-bangalore-logo.png';
import logo6 from '../../../Assets/Logo Wall/31XM+-RSRfL.png';
import logo7 from '../../../Assets/Logo Wall/360_197_1.png';
import logo8 from '../../../Assets/Logo Wall/409-4092173_london-dairy-brands-of-ice-cream-in-india.png';
import logo9 from '../../../Assets/Logo Wall/Amazon_Pay-Logo.wine.png';
import logo10 from '../../../Assets/Logo Wall/Ashok-Leyland-Logo.png';
import logo11 from '../../../Assets/Logo Wall/Condé_Nast_logo.svg.png';
import logo12 from '../../../Assets/Logo Wall/Dabur-Logo.wine.png';
import logo13 from '../../../Assets/Logo Wall/Diageo-symbol.png';
import logo14 from '../../../Assets/Logo Wall/Fastrack_logo.png';
import logo15 from '../../../Assets/Logo Wall/Garnier.png';
import logo16 from '../../../Assets/Logo Wall/Fastrack_logo.png';
import logo17 from '../../../Assets/Logo Wall/Guinness_World_Records_logo.svg.png';
import logo18 from '../../../Assets/Logo Wall/Häagen-Dazs_Logo.svg.png';
import logo19 from '../../../Assets/Logo Wall/IDEMIA_logo.svg.png';
import logo20 from '../../../Assets/Logo Wall/Intel_logo_(2006-2020).svg.png';
import logo21 from '../../../Assets/Logo Wall/Itc hotel.png';
import logo22 from '../../../Assets/Logo Wall/Jaegermeister-removebg-preview.png';
import logo23 from '../../../Assets/Logo Wall/Johnsons-Baby-Logo.png';
// import logo24 from '../../../Assets/Logo Wall/Layer 0.png';
import logo25 from '../../../Assets/Logo Wall/Logo_True_Colors.png';
import logo26 from '../../../Assets/Logo Wall/Maybelline-Logo.png';
import logo27 from '../../../Assets/Logo Wall/Myntra-Logo.png';
import logo28 from '../../../Assets/Logo Wall/Pepsico logo.png';
import logo29 from '../../../Assets/Logo Wall/Pidilite_logo.svg.png';
import logo30 from '../../../Assets/Logo Wall/Redmi_by_Xiaomi_Logo.svg.png';
import logo31 from '../../../Assets/Logo Wall/Reliance_Jio_Logo_(October_2015).svg.png';
import logo32 from '../../../Assets/Logo Wall/TY5HJU26.png';
import logo33 from '../../../Assets/Logo Wall/Titan_Watches_logo.png';
import logo34 from '../../../Assets/Logo Wall/UPL_official_logo.svg.png';
import logo35 from '../../../Assets/Logo Wall/Unacademy_logo.png';
import logo36 from '../../../Assets/Logo Wall/Vogue-logo.png';
import logo37 from '../../../Assets/Logo Wall/a877f83d2694f439080c3e8348915db3e04f9aee-1080x1080.png';
import logo38 from '../../../Assets/Logo Wall/Xiaomi-logo.png';
import logo39 from '../../../Assets/Logo Wall/acko-logo-knowledge-panel.png';
import logo40 from '../../../Assets/Logo Wall/brigade-logo_horizontal-2048px.png';
import logo41 from '../../../Assets/Logo Wall/britannia-industries6604.png';
import logo42 from '../../../Assets/Logo Wall/coinswitch-kuber-original-1b9e4a324b8893c2df0104fd06a10861.png';
import logo43 from '../../../Assets/Logo Wall/download.png';
import logo44 from '../../../Assets/Logo Wall/finolex-pipes-111-6.png';
import logo45 from '../../../Assets/Logo Wall/img_19112021_140307_800_x_800_pixel.png';
import logo46 from '../../../Assets/Logo Wall/indiamart-logo-DA6C405983-seeklogo.com.png';
import logo47 from '../../../Assets/Logo Wall/infosys-logo-jpeg.png';
import logo48 from '../../../Assets/Logo Wall/logo azah.png';
import logo49 from '../../../Assets/Logo Wall/motherson-innovations-vector-logo-2022.png';
import logo50 from '../../../Assets/Logo Wall/pillsbury-logo-E78810B8B7-seeklogo.com.png';
import logo51 from '../../../Assets/Logo Wall/porter.png';

const logos_first_half = [
  logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10,
  logo11, logo12, logo13, logo14, logo15, logo16, logo17, logo18, logo19, logo20,
  logo21, logo22, logo23, logo25];
const logos_second_half = [
  logo26, logo27, logo28, logo29, logo30,
  logo31, logo32, logo33, logo34, logo35, logo36, logo37, logo38, logo39, logo40,
  logo41, logo42, logo43, logo44, logo45, logo46, logo47, logo48, logo49, logo50,
  logo51
]

export { logos_first_half, logos_second_half };
