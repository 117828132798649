import './Brands.css'
import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { logos_first_half, logos_second_half } from './logos';

import BrandCard from './BrandCard';
const Brands = () => {
  useEffect(() => {
    Aos.init({
      duration: 900
    })
  }, [])

  if (window.innerWidth >= 500) {
    return (
      <div >
        <p className='brands-heading' data-aos="fade-in">
          Brands founders have worked on
        </p>
        <div className="brands card" >

          <div className='brands-left-slider' data-aos="zoom-out">
            {[...logos_first_half, ...logos_first_half].map((logo, index) => (
              <BrandCard key={index} source={logo} />
            ))}
          </div>
          <div className='brands-right-slider' data-aos="zoom-out">
            {[...logos_second_half, ...logos_second_half].map((logo, index) => (
              <BrandCard key={index} source={logo} />
            ))}
          </div>
        </div>
      </div>
    );
  }
  else {
    return (
      <>
        <p className='brands-heading' >
          Brands founders have worked on
        </p>
        <div className='mobile-brands-container' >
          <div className='mobile-brands-slider'>
            <div className='mobile-brands-left-slider-container'>
              <div className='mobile-brands-left-slider'>
                {[...logos_first_half, ...logos_first_half, ...logos_first_half].map((logo, index) => (
                  <BrandCard key={index} source={logo} />
                ))}
                </div>
            </div>
            <div className='mobile-brands-right-slider-container'>
              <div className='mobile-brands-right-slider'>
                {[...logos_second_half, ...logos_second_half, ...logos_second_half].map((logo, index) => (
                  <BrandCard key={index} source={logo} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Brands;
