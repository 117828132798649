import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios';
import './Default.css'
import blue_logo from '../../../Assets/blue_logo.png'
import Tiles from './Tiles/Tiles'
import close from '../../../Assets/cross.png'
import arrow from '../../../Assets/dropdown1.png';
import email_img from '../../../Assets/email.png'
import instagram from '../../../Assets/instagram.png'
import linkedin from '../../../Assets/linkedin.png'
import discord from '../../../Assets/discord-mark-black.png'
import prev from '../../../Assets/prev_arrow.png'
import next from '../../../Assets/prev_arrow.png'
import Aos from 'aos';
import 'aos/dist/aos.css';
const Default = () => {
    useEffect(() => {
        Aos.init({
            duration: 900
        });
    }, []);

    const [showForm, setShowForm] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(1);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedGender, setSelectedGender] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => setShowForm(!showForm);

    const moveAhead = () => setCurrentSlide(currentSlide + 1);
    const moveBack = () => setCurrentSlide(currentSlide - 1);


    const options = [
        'Film/Video', 'Photography', 'Sound/Music', 'Digital Art',
        'Marketer', 'Project Manager', 'Other'
    ];

    const years = [
        'Fresher', 'Less than 1 year', '1-3 years', '3-5 years',
        '5-8 years', '8-12 years', 'More than 12 years'
    ];

    const genders = [
        'Female', 'Male', 'Other'
    ]




    const [count, setCount] = useState("loading...");
    const SCRIPT_URL = 'https://script.google.com/macros/s/AKfycbylW3IVpmPEM9Yv8fa6JyXTN6jvbCI9-tKE-xFHMgxPyLwMLL_WHKsr_91M1st09GI/exec';

    useEffect(() => {
        fetchCount();
    }, []);

    const fetchCount = async () => {
        try {
            const response = await axios.get(SCRIPT_URL);
            setCount(parseInt(response.data));
        } catch (error) {
            console.error('Error fetching count:', error);
        }
    };
    const form = useRef();

    const sendToSheet = (e) => {

        const formElement = document.querySelector(".creators-form")
        e.preventDefault();
        const formData = new FormData(formElement)
        fetch(SCRIPT_URL, {
            method: "POST",
            body: formData
        }).catch((error) => console.log(error));

    }

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');

    const validateName = () => {
        if (name.trim() === '') {
            setNameError('Name is required');
            return false;
        }

        if (!/^[a-zA-Z\s]+$/.test(name)) {
            setNameError('Name should only contain letters and spaces');
            return false;
        }

        if (name.length < 3 || name.length > 50) {
            setNameError('Name should be between 3 and 50 characters');
            return false;
        }

        setNameError('');
        return true;
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };
    const handleNameSubmit = () => {
        if (validateName()) {
            moveAhead();
        }
    }


    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState('');

    const validateCity = () => {
        if (city.trim() === '') {
            setCityError('City and state are required');
            return false;
        }

        

        if (city.length > 100) {
            setCityError('Entry should be less than 100 characters');
            return false;
        }

        setCityError('');
        return true;
    };

    const handleCityChange = (e) => {
        setCity(e.target.value);
    };

    const handleCitySubmit = () => {
        if (validateCity()) {
            moveAhead();
        }
    };




    const [mediumError, setMediumError] = useState('');

    const validateMedium = () => {
        if (selectedOption === '') {
            setMediumError('Please select an option');
            return false;
        }

        setMediumError('');
        return true;
    };

    const handleMediumSubmit = () => {
        if (validateMedium()) {
            moveAhead();
        }
    };




    const [job, setJob] = useState('');
    const [jobError, setJobError] = useState('');

    const validateJob = () => {
        if (job.trim() === '') {
            setJobError('Job role is required');
            return false;
        }



        if (job.length < 2 || job.length > 50) {
            setJobError('Job role should be between 2 and 50 characters');
            return false;
        }

        setJobError('');
        return true;
    };

    const handleJobChange = (e) => {
        setJob(e.target.value);
    };
    const handleJobSubmit = () => {
        if (validateJob()) {
            moveAhead();
        }
    }



    const [work, setWork] = useState('');
    const [workError, setWorkError] = useState('');
    const [workCharCount, setWorkCharCount] = useState(0);
    const validateWork = () => {
        if (work.trim() === '') {
            setWorkError('This description is required');
            return false;
        }


        if (work.length < 100) {
            setWorkError('This description should be at least of 100 characters');
            return false;
        }

        setWorkError('');
        return true;
    };

    const handleWorkChange = (e) => {
        setWork(e.target.value);
        setWorkCharCount(e.target.value.length);
    };
    const handleWorkSubmit = () => {
        if (validateWork()) {
            moveAhead();
        }
    }



    const [pitch, setPitch] = useState('');
    const [pitchError, setPitchError] = useState('');
    const [pitchCharCount, setPitchCharCount] = useState(0);
    const validatePitch = () => {
        if (pitch.trim() === '') {
            setPitchError('This description is required');
            return false;
        }


        if (pitch.length < 100) {
            setPitchError('This description should be at least of 100 characters');
            return false;
        }

        setPitchError('');
        return true;
    };

    const handlePitchChange = (e) => {
        setPitch(e.target.value);
        setPitchCharCount(e.target.value.length);
    };
    const handlePitchSubmit = () => {
        if (validatePitch()) {
            moveAhead();
        }
    }




    const [yearError, setYearError] = useState('');

    const validateYear = () => {
        if (selectedYear === '') {
            setYearError('Please select an option');
            return false;
        }

        setYearError('');
        return true;
    };

    const handleYearSubmit = () => {
        if (validateYear()) {
            moveAhead();
        }
    };



    const [website, setWebsite] = useState('');
    const [websiteError, setWebsiteError] = useState('');

    const validateWebsite = () => {
        if (website.trim() === '') {
            setWebsiteError('Website URL is required');
            return false;
        }

        // Regular expression for URL validation
        const urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
        if (!urlRegex.test(website)) {
            setWebsiteError('Please enter a valid URL');
            return false;
        }

        setWebsiteError('');
        return true;
    };

    const handleWebsiteChange = (e) => {
        setWebsite(e.target.value);
    };

    const handleWebsiteSubmit = () => {
        if (validateWebsite()) {
            moveAhead();
        }
    };




    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const validateEmail = () => {
        if (email.trim() === '') {
            setEmailError('Email is required');
            return false;
        }

        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address');
            return false;
        }

        setEmailError('');
        return true;
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleEmailSubmit = () => {
        if (validateEmail()) {
            moveAhead();
        }
    };




    const [countryCode, setCountryCode] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');


    const validatePhone = () => {
        if (phone.trim() === '') {
            setPhoneError('Phone number is required');
            return false;
        }

        const phoneRegex = /^\d{10}$/;  // Assumes 10-digit phone number
        if (!phoneRegex.test(phone)) {
            setPhoneError('Invalid phone number');
            return false;
        }

        setPhoneError('');
        return true;
    };

    const handleCountryCodeChange = (e) => {
        setCountryCode(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const handlePhoneSubmit = () => {
        const isPhoneValid = validatePhone();

        if (isPhoneValid) {
            moveAhead();
        }
    };




    const [dob, setDob] = useState('');
    const [dobError, setDobError] = useState('');

    const validateDob = () => {
        if (dob.trim() === '') {
            setDobError('Date of Birth is required');
            return false;
        }

        const birthDate = new Date(dob);
        const currentDate = new Date();

        // Check if the date is in the past
        if (birthDate > currentDate) {
            setDobError('Date of Birth cannot be in the future');
            return false;
        }

        // Check if the person is at least 18 years old
        const ageDifference = currentDate.getFullYear() - birthDate.getFullYear();
        const monthDifference = currentDate.getMonth() - birthDate.getMonth();
        if (ageDifference < 12 || (ageDifference === 12 && monthDifference < 0)) {
            setDobError('You must be at least 12 years old');
            return false;
        }

        if (ageDifference > 100) {
            setDobError('Please enter a valid date of birth');
            return false;
        }

        setDobError('');
        return true;
    };

    const handleDobChange = (e) => {
        setDob(e.target.value);
    };

    const handleDobSubmit = () => {
        if (validateDob()) {
            moveAhead();
        }
    };



 
    const [genderError, setGenderError] = useState('');

    const validateGender = () => {
        if (selectedGender === '') {
            setGenderError('Please select an option');
            return false;
        }

        setGenderError('');
        return true;
    };

    const handleGenderSubmit = () => {
        if (validateGender()) {
            moveAhead();
        }
    };

    const openDiscord = () =>{
        window.open('https://discord.gg/pdQZVYg6m6', '_blank');
    }
    const refresh= ()=> { 
        if(window.innerWidth>450)window.location.reload();
     }
    useEffect(() => {
        window.addEventListener('resize', refresh);
        return () => {
          window.removeEventListener('resize', refresh);
        };
      }, []);

    return (
        <>
            <div className='default' >
                <div className='default-header' data-aos="zoom-out">
                    <div className='logo-header'>
                        <img src={blue_logo} alt='logo' className='default-logo' />
                        <p className='logo-header-text'>Creator Circle</p>
                    </div>
                    <div className='lets-go' onClick={handleClick}>
                        <p className='lets-go-text'>Let's go</p>
                    </div>
                </div>
                <div className='default-description' data-aos="zoom-out">
                    <p className='default-description-text'>
                        With this community we aim to connect creative talent around the country with
                        brands and clients who can make use of their services. Creator Circle is a place
                        where you can share ideas, learn new skills and make friendly connections
                    </p>
                </div>

                <div className='tiles' ><Tiles /> </div>











                <div className='creators-form-container' style={{ 'display': showForm ? 'flex' : 'none' }} >





                    <form className='creators-form' onSubmit={sendToSheet} ref={form}>

                        <div className='form-slide form-slide-1' style={{ 'display': (currentSlide === 1) ? 'flex' : 'none' }}>

                            <div className='close-form'>
                                <img className='arrow-icon prev-icon' alt='previous' src={prev}  onClick={handleClick}/>
                                <img className='arrow-icon' alt='next' src={next} onClick={handleNameSubmit} />
                                <img className='close-icon' alt='close-form' src={close} onClick={handleClick} />
                            </div>

                            <div className='details'>
                                <p className='form-header'>1. What is your full name?</p>
                                <p className='form-footer'>Please enter your legal name as it appears on your official ID documents</p>
                                <input
                                    type='text'
                                    name='creator_name'
                                    className='creator-name'
                                    value={name}
                                    onChange={handleNameChange}
                                    required
                                />
                                {nameError && <p className='error-message'>{nameError}</p>}
                            </div>
                            <div className='form-button' onClick={handleNameSubmit}>
                                <p className='form-button-text'>OK</p>
                            </div>
                        </div>

                        <div className='form-slide form-slide-2' style={{ 'display': (currentSlide === 2) ? 'flex' : 'none' }}>
                            <div className='close-form'>
                                <img className='arrow-icon prev-icon' alt='previous' src={prev}  onClick={moveBack}/>
                                <img className='arrow-icon' alt='next' src={next} onClick={handleCitySubmit} />
                                <img className='close-icon' alt='close-form' src={close} onClick={handleClick} />
                            </div>
                            <div className='details'>
                                <p className='form-header'>2. What city, state are you based in?</p>
                                <p className='form-footer'>Example "Delhi, India"</p>
                                <input required type='text' name='creator_city' className='creator-name' value={city} onChange={handleCityChange} />
                                {cityError && <p className='error-message'>{cityError}</p>}
                            </div>
                            <div className='form-button' onClick={handleCitySubmit}>
                                <p className='form-button-text'>OK</p>
                            </div>
                        </div>


                        <div className='form-slide form-slide-3' style={{ 'display': (currentSlide === 3) ? 'flex' : 'none' }}>
                            <div className='close-form'>
                                <img className='arrow-icon prev-icon' alt='previous' src={prev}  onClick={moveBack}/>
                                <img className='arrow-icon' alt='next' src={next} onClick={handleMediumSubmit} />
                                <img className='close-icon' alt='close-form' src={close} onClick={handleClick} />
                            </div>
                            <div className='details'>
                                <p className='form-header'>3. What is your medium?</p>
                                <div className='dropdown-container'>
                                    <div
                                        className='dropdown-header'
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        {selectedOption || 'Select an option'}
                                        <img src={arrow} alt="dropdown arrow" className={isOpen?'dropdown-arrow-rotated':'dropdown-arrow'} />
                                    </div>
                                    {isOpen && (
                                        <ul className='dropdown-list'>
                                            {options.map((option, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => {
                                                        setSelectedOption(option);
                                                        setIsOpen(false);
                                                    }}
                                                >
                                                    {option}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <input type='hidden' name='creator_medium' value={selectedOption} />
                                {mediumError && <p className='error-message'>{mediumError}</p>}

                            </div>
                            <div className='form-button' onClick={handleMediumSubmit}>
                                <p className='form-button-text'>OK</p>
                            </div>
                        </div>


                        <div className='form-slide form-slide-4' style={{ 'display': (currentSlide === 4) ? 'flex' : 'none' }}>
                            <div className='close-form'>
                                <img className='arrow-icon prev-icon' alt='previous' src={prev}  onClick={moveBack}/>
                                <img className='arrow-icon' alt='next' src={next} onClick={handleJobSubmit} />
                                <img className='close-icon' alt='close-form' src={close} onClick={handleClick} />
                            </div>
                            <div className='details'>
                                <p className='form-header'>4. What is your job role?</p>
                                <p className='form-footer'>Example "video editor" or "costume stylist"</p>
                                <input required type='text' name='creator_job' className='creator-name' value={job} onChange={handleJobChange} />
                                {jobError && <p className='error-message'>{jobError}</p>}
                            </div>
                            <div className='form-button' onClick={handleJobSubmit}>
                                <p className='form-button-text'>OK</p>
                            </div>
                        </div>


                        <div className='form-slide form-slide-5' style={{ 'display': (currentSlide === 5) ? 'flex' : 'none' }}>
                            <div className='close-form'>
                                <img className='arrow-icon prev-icon' alt='previous' src={prev}  onClick={moveBack}/>
                                <img className='arrow-icon' alt='next' src={next} onClick={handleWorkSubmit} />
                                <img className='close-icon' alt='close-form' src={close} onClick={handleClick} />
                            </div>
                            <div className='details'>
                                <p className='form-header'>5. How would you describe what you do everyday?</p>
                                <p className='form-footer'>Minimum 100 characters</p>
                                <textarea type='text' name='creator_everyday_work' className='creator-everyday-work' value={work} onChange={handleWorkChange} />
                                {workError && <p className='error-message'>{workError}</p>}
                                {(workCharCount<100) && <p className='form-footer'> Enter at least {100-workCharCount} characters more  </p>}
                            </div>
                            <div className='form-button' onClick={handleWorkSubmit}>
                                <p className='form-button-text'>OK</p>
                            </div>
                        </div>

                        <div className='form-slide form-slide-6' style={{ 'display': (currentSlide === 6) ? 'flex' : 'none' }}>
                            <div className='close-form'>
                                <img className='arrow-icon prev-icon' alt='previous' src={prev}  onClick={moveBack}/>
                                <img className='arrow-icon' alt='next' src={next} onClick={handlePitchSubmit} />
                                <img className='close-icon' alt='close-form' src={close} onClick={handleClick} />
                            </div>
                            <div className='details'>
                                <p className='form-header'>6. What type of work do you want to do?</p>
                                <p className='form-footer'>you can talk about industries you want to work for/ your elevator pitch</p>
                                <textarea type='text' name='creator_pitch' className='creator-pitch' value={pitch} onChange={handlePitchChange} />
                                {pitchError && <p className='error-message'>{pitchError}</p>}
                                {(pitchCharCount<100) && <p className='form-footer'> Enter at least {100-pitchCharCount} characters more. </p>}

                            </div>
                            <div className='form-button' onClick={handlePitchSubmit}>
                                <p className='form-button-text'>OK</p>
                            </div>
                        </div>


                        <div className='form-slide form-slide-7' style={{ 'display': (currentSlide === 7) ? 'flex' : 'none' }}>
                            <div className='close-form'>
                                <img className='arrow-icon prev-icon' alt='previous' src={prev}  onClick={moveBack}/>
                                <img className='arrow-icon' alt='next' src={next} onClick={handleYearSubmit} />
                                <img className='close-icon' alt='close-form' src={close} onClick={handleClick} />
                            </div>
                            <div className='details'>
                                <p className='form-header'>7. How many years have you been working for?</p>
                                <div className='dropdown-container'>
                                    <div
                                        className='dropdown-header'
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        {selectedYear || 'Select an option'}
                                        <img src={arrow} alt="dropdown arrow" className={isOpen?'dropdown-arrow-rotated':'dropdown-arrow'} />
                                    </div>
                                    {isOpen && (
                                        <ul className='dropdown-list'>
                                            {years.map((year, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => {
                                                        setSelectedYear(year);
                                                        setIsOpen(false);
                                                    }}
                                                >
                                                    {year}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <input  type='hidden' name='creator_experience' value={selectedYear} />
                                {yearError && <p className='error-message'>{yearError}</p>}
                            </div>
                            <div className='form-button' onClick={handleYearSubmit}>
                                <p className='form-button-text'>OK</p>
                            </div>
                        </div>

                        <div className='form-slide form-slide-8' style={{ 'display': (currentSlide === 8) ? 'flex' : 'none' }}>
                            <div className='close-form'>
                                <img className='arrow-icon prev-icon' alt='previous' src={prev}  onClick={moveBack}/>
                                <img className='arrow-icon' alt='next' src={next} onClick={moveAhead} />
                                <img className='close-icon' alt='close-form' src={close} onClick={handleClick} />
                            </div>
                            <div className='details'>
                                <p className='form-header'>8. Instagram</p>
                                <p className='form-footer'>Please enter your instagram handle. For example: "@ekuser"</p>
                                <input required type='text' name='creator_instagram' className='creator-name' />
                            </div>
                            <div className='form-button' onClick={moveAhead} >
                                <p className='form-button-text'>OK</p>
                            </div>
                        </div>

                        <div className='form-slide form-slide-9' style={{ 'display': (currentSlide === 9) ? 'flex' : 'none' }}>
                            <div className='close-form'>
                                <img className='arrow-icon prev-icon' alt='previous' src={prev}  onClick={moveBack}/>
                                <img className='arrow-icon' alt='next' src={next} onClick={moveAhead} />
                                <img className='close-icon' alt='close-form' src={close} onClick={handleWebsiteSubmit} />
                            </div>
                            <div className='details'>
                                <p className='form-header'>9. Portfolio website</p>
                                <input required type='text' name='creator_portfolio' className='creator-name' value={website} onChange={handleWebsiteChange} />
                                {websiteError && <p className='error-message'>{websiteError}</p>}
                            </div>
                            <div className='form-button' onClick={handleWebsiteSubmit}>
                                <p className='form-button-text'>OK</p>
                            </div>
                        </div>

                        <div className='form-slide form-slide-10' style={{ 'display': (currentSlide === 10) ? 'flex' : 'none' }}>
                            <div className='close-form'>
                                <img className='arrow-icon prev-icon' alt='previous' src={prev}  onClick={moveBack}/>
                                <img className='arrow-icon' alt='next' src={next} onClick={handleEmailSubmit} />
                                <img className='close-icon' alt='close-form' src={close} onClick={handleClick} />
                            </div>
                            <div className='details'>
                                <p className='form-header'>10. Email</p>
                                <input required type='email' name='creator_email' className='creator-name' value={email} onChange={handleEmailChange} />
                                {emailError && <p className='error-message'>{emailError}</p>}
                            </div>
                            <div className='form-button' onClick={handleEmailSubmit}>
                                <p className='form-button-text'>OK</p>
                            </div>
                        </div>

                        <div className='form-slide form-slide-11' style={{ 'display': (currentSlide === 11) ? 'flex' : 'none' }}>
                            <div className='close-form'>
                                <img className='arrow-icon prev-icon' alt='previous' src={prev}  onClick={moveBack}/>
                                <img className='arrow-icon' alt='next' src={next} onClick={handlePhoneSubmit} />
                                <img className='close-icon' alt='close-form' src={close} onClick={handleClick} />
                            </div>
                            <div className='details'>
                                <p className='form-header'>11. Phone Number</p>
                                <div className='phone-number'>
                                    <input type='text' name='country_code' className='country-code' placeholder='+91' value={countryCode || '+91'} onChange={handleCountryCodeChange} />
                                    <input required type='tel' name='creator_phone' className='creator-name creator-phone' value={phone} onChange={handlePhoneChange} />
                                    {phoneError && <p className='error-message'>{phoneError}</p>}
                                </div>
                            </div>
                            <div className='form-button' onClick={handlePhoneSubmit}>
                                <p className='form-button-text'>OK</p>
                            </div>
                        </div>


                        <div className='form-slide form-slide-12' style={{ 'display': (currentSlide === 12) ? 'flex' : 'none' }}>
                            <div className='close-form'>
                                <img className='arrow-icon prev-icon' alt='previous' src={prev}  onClick={moveBack}/>
                                <img className='arrow-icon' alt='next' src={next} onClick={handleDobSubmit} />
                                <img className='close-icon' alt='close-form' src={close} onClick={handleClick} />
                            </div>
                            <div className='details'>
                                <p className='form-header'>12. Date of Birth</p>

                                <input required type='date' name='creator_dob' className='calendar' value={dob} onChange={handleDobChange} />
                                {dobError && <p className='error-message'>{dobError}</p>}

                            </div>
                            <div className='form-button' onClick={handleDobSubmit}>
                                <p className='form-button-text'>OK</p>
                            </div>
                        </div>

                        <div className='form-slide form-slide-13' style={{ 'display': (currentSlide === 13) ? 'flex' : 'none' }}>
                            <div className='close-form'>
                                <img className='arrow-icon prev-icon' alt='previous' src={prev}  onClick={moveBack}/>
                                <img className='arrow-icon' alt='next' src={next} onClick={handleGenderSubmit} />
                                <img className='close-icon' alt='close-form' src={close} onClick={handleClick} />
                            </div>
                            <div className='details'>
                                <p className='form-header'>13. Gender</p>
                                <div className='dropdown-container'>
                                    <div
                                        className='dropdown-header'
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        {selectedGender || 'Select an option'}
                                        <img src={arrow} alt="dropdown arrow" className={isOpen?'dropdown-arrow-rotated':'dropdown-arrow'} />
                                    </div>
                                    {isOpen && (
                                        <ul className='dropdown-list'>
                                            {genders.map((gender, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => {
                                                        setSelectedGender(gender);
                                                        setIsOpen(false);
                                                    }}
                                                >
                                                    {gender}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <input type='hidden' name='gender' value={selectedGender} />
                                {genderError && <p className='error-message'>{genderError}</p>}
                            </div>
                            <div className='form-button' onClick={handleGenderSubmit}>
                                <p className='form-button-text'>OK</p>
                            </div>
                        </div>


                        <div className='form-slide form-slide-14' style={{ 'display': (currentSlide === 14) ? 'flex' : 'none' }}>
                            <div className='close-form'>
                                <img className='arrow-icon prev-icon' alt='previous' src={prev}  onClick={moveBack}/>
                                <img className='arrow-icon' alt='next' src={next} onClick={handleClick} />
                                <img className='close-icon' alt='close-form' src={close} onClick={handleClick} />
                            </div>
                            <div className='details'>
                                <p className='form-header'>Terms</p>
                                <p className='form-footer'>
                                    Ek Idea Creator Circle Membership Terms
                                    <br /><br />1. I understand that I am being invited to join Ek Idea Membership and that
                                    Ek Idea is not obligated to provide any specific services to me.
                                    <br /><br />2. I acknowledge that some opportunities shared by Ek Idea may be suitable
                                    for me, while others may not be.
                                    <br /><br />3. I am joining this membership because I value Ek Idea's creator circle community.
                                    <br /><br />4. I understand that my Ek Idea Membership may be terminated if
                                    I fail to treat other members or Ek Idea staff with respect.
                                    <br /><br />5. I agree that my membership may be revoked if I violate the membership
                                    guidelines or share confidential information about Ek Idea, its clients.
                                    or other members with any unauthorized parties.
                                    <br /><br />6. I commit to maintaining the privacy and integrity of Ek Idea's community.
                                    
                                </p>
                            </div>
                            
                            <button className='creator-submit' type='submit' value='Send' onClick={moveAhead} > Accept & Submit</button>
                            
                        </div>
                        </form>

                        <div className='form-slide form-slide-15' style={{ 'display': (currentSlide === 15) ? 'flex' : 'none' }}>
                            <div className='close-form'>
                                <img className='close-icon' alt='close-form' src={close} onClick={handleClick} />
                            </div>
                            <div className='details'>
                                <p className='form-header'>Congratulations you are in the circle now.</p>
                                <p className='form-footer'>
                                    We are working towards finding stage for talented individuals like you,<br/>
                                    meanwhile you can also join our Discord group, It is a place to
                                </p>
                                <p className='form-footer-2'>
                                    Network
                                    <br />Learn
                                    <br />Grow
                                    <br />Find your team
                                    <br />Find industry reports and other resources
                                </p>
                            </div>
                            <div className='slide-15-buttons'>

                
                                <div onClick={openDiscord}  className='discord-button' style={{cursor:'pointer'}}>
                                    <button style={{cursor:'pointer'}}><p className='discord-button-text'>Let's join Discord</p></button>
                                </div>

                            </div>
                        </div>



                   



                    <div className='creators-form-footer'>
                        <p className='creators-form-footer-text'>Ek Idea Creator Circle</p>
                        <div className='form-carousel-dots'>
                            <ul className='form-dots'>
                                <li className={currentSlide >= 1 ? 'form-colored-dot' : 'form-dot'}></li>
                                <li className={currentSlide >= 2 ? 'form-colored-dot' : 'form-dot'}></li>
                                <li className={currentSlide >= 3 ? 'form-colored-dot' : 'form-dot'}></li>
                                <li className={currentSlide >= 4 ? 'form-colored-dot' : 'form-dot'}></li>
                                <li className={currentSlide >= 5 ? 'form-colored-dot' : 'form-dot'}></li>
                                <li className={currentSlide >= 6 ? 'form-colored-dot' : 'form-dot'}></li>
                                <li className={currentSlide >= 7 ? 'form-colored-dot' : 'form-dot'}></li>
                                <li className={currentSlide >= 8 ? 'form-colored-dot' : 'form-dot'}></li>
                                <li className={currentSlide >= 9 ? 'form-colored-dot' : 'form-dot'}></li>
                                <li className={currentSlide >= 10 ? 'form-colored-dot' : 'form-dot'}></li>
                                <li className={currentSlide >= 11 ? 'form-colored-dot' : 'form-dot'}></li>
                                <li className={currentSlide >= 12 ? 'form-colored-dot' : 'form-dot'}></li>
                                <li className={currentSlide >= 13 ? 'form-colored-dot' : 'form-dot'}></li>
                                <li className={currentSlide >= 14 ? 'form-colored-dot' : 'form-dot'}></li>
                                <li className={currentSlide >= 15 ? 'form-colored-dot' : 'form-dot'}></li>


                            </ul>
                        </div>
                    </div>
                </div>



            </div>



            <div className='count-box' style={{ 'display': showForm ? 'none' : 'flex' }}>
                <div className='count-container' >
                    <div className='member-count'>
                        <p className='count-text'>Member count</p>
                        <p className='count-number'>{count}</p>
                    </div>
                    <div className='socials'>
                        <a href='https://www.instagram.com/ekidea.in/' target='_blank' rel="noreferrer"><img src={instagram} alt='instagram' className='icon' /></a>
                        <a href='https://discord.gg/pdQZVYg6m6' target='_blank' rel="noreferrer" ><img src={discord} alt='discord' className='icon' id='discord' /></a>
                        <a href='https://www.linkedin.com/company/ekidea/' target='_blank' rel="noreferrer"><img src={linkedin} alt='linkedin' className='icon' /></a>
                        <a href='mailto:connect@ekidea.in' ><img src={email_img} alt='email' className='icon' /> </a>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Default