import React, { useEffect } from 'react'
import './Intro.css'
import pigeon from '../../../Assets/Bird.png'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom'
const Intro = () => {
  useEffect(()=>{
    Aos.init({
      duration: 900
    })
  },[])
  return ( 
    <>
    <div className='intro card active' >
      <img className='intro-bg' alt='white pigeon' src={pigeon} data-aos="zoom-out"  data-aos-delay="200" />
      <p  className='intro-heading' data-aos ="fade-left" data-aos-delay="200">Ek Idea</p>
      <p className='intro-footer' data-aos ="fade-left" data-aos-delay="200">Digital content production agency</p>
      <div className='intro-content'>
        <Link to={"/about"}><span className='left'  data-aos="zoom-in" data-aos-offset='0' >We speak <br />Visuals, Internet and English</span></Link>
        <Link to={"/"}><span className='right' data-aos="zoom-in" data-aos-offset='0' >Delhi + Mumbai</span></Link>
      </div>
    </div>

    <div className='mobile-intro' >
      <img className='mobile-intro-bg' alt='white pigeon ' src={pigeon}  />
      <p  className='mobile-intro-heading'>Ek Idea</p>
      <p className='mobile-intro-footer'>Digital content production agency<br/>Delhi + Mumbai</p>
      <div className='mobile-intro-content'>
        <Link to={"/about"}><span className='left' >We speak <br />Visuals, Internet and English</span></Link>
      </div>
    </div>
    </>
  )
}

export default Intro