import React, { useEffect } from 'react'
import './Bakery.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import cookie from '../../../Assets/cookie.png'
const Bakery = () => {
    useEffect(() => {
        Aos.init({
            duration: 900
        })
    }, []);

    return (
        <>
            <div className='Bakery card' >
                <div className='bakery-header'>
                    <p className='page-2-heading' data-aos="zoom-in">The Content Bakery</p>
                    <p className='bakery-footer' data-aos="zoom-in">We bake content for brands</p>
                </div>
                <ul className='green-bullets' data-aos="zoom-in">
                    <li className='green-bullet'>Good Work</li>
                    <li className='green-bullet'>Good People</li>
                </ul>

                <ul className='blue-bullets' data-aos="zoom-in">
                    <li className='blue-bullet'>Experienced Founders</li>
                    <li className='blue-bullet'>50+ Brands</li>
                </ul>


                <ul className='yellow-bullets' data-aos="zoom-in">
                    <li className='yellow-bullet'>Ad Films</li>
                    <li className='yellow-bullet'>Digital Content</li>
                    <li className='yellow-bullet'>Social Media</li>
                </ul>


                <ul className='pink-bullets' data-aos="zoom-in">
                    <li className='pink-bullet'>Content</li>
                    <li className='pink-bullet'>Production</li>
                    <li className='pink-bullet'>Agency</li>
                    <li className='pink-bullet'>Delhi+Mumbai</li>
                </ul>
            </div>
 
            <div className='mobile-Bakery' >
                <div className='mobile-bakery-header'>
                    <p className='mobile-page-2-heading' >The <br/> Content<br/> Bakery</p>
                    <p className='mobile-bakery-footer'>We bake content for brands</p>
                </div>
                <img src={cookie} className='mobile-cookie' alt='cookie'  />
                <div className='mobile-page2-bullets'>
                <ul className='mobile-green-bullets' >
                    <li className='mobile-green-bullet'>Good Work</li>
                    <li className='mobile-green-bullet'>Good People</li>
                </ul>

                <ul className='mobile-blue-bullets'>
                    <li className='mobile-blue-bullet'>Experienced Founders</li>
                    <li className='mobile-blue-bullet'>50+ Brands</li>
                </ul>


                <ul className='mobile-yellow-bullets'>
                    <li className='mobile-yellow-bullet'>Ad Films</li>
                    <li className='mobile-yellow-bullet'>Digital Content</li>
                    <li className='mobile-yellow-bullet'>Social Media</li>
                </ul>


                <ul className='mobile-pink-bullets' >
                    <li className='mobile-pink-bullet'>Content</li>
                    <li className='mobile-pink-bullet'>Production</li>
                    <li className='mobile-pink-bullet'>Agency</li>
                    <li className='mobile-pink-bullet'>Delhi+Mumbai</li>
                </ul>
                </div>
            </div>
        </>
    )
}

export default Bakery