import React , {useEffect} from 'react'
import './Slide1.css'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Slide1 = () => {
    useEffect(() => {
        Aos.init({
          duration: 900
        })
      }, []);

    if(window.innerWidth>500){
        
        return (
            <div className='slide-1'>
                <div className='page2-title page2-title-1'>
                    <p data-aos="zoom-in">
                        Ek idea builds when we build your brand
                    </p>
                </div>
                <div className='page2-text-container' id='slide-1-text-container'>
                    <p className='page2-description' data-aos="zoom-in">
                        When we say "build your brand" it means having better numbers on your dashboard coming month, having a voice that resonates with customer's sentiment, building a community in motion that thinks for you in years and it means everything that's in between.
                        <br /><br />There is no one size fits all strategy, even for a company who has cracked ads will have to piviot with market sentiment and growth. We look forward to building a legacy and be sustainable in the process.
                        <br /><br /><b>Squeeze when juice is worth the squeeze.
                        <br />Juice is always worth the squeeze</b></p>
                </div>
            </div>
        )} else{
            return (
                <div className='slide-1'>
                    <div className='page2-title page2-title-1'>
                        <p>
                            Ek idea builds when we build your brand
                        </p>
                    </div>
                    <div className='page2-text-container' id='slide-1-text-container'>
                        <p className='page2-description'>
                            When we say "build your brand" it means having better numbers on your dashboard coming month, having a voice that resonates with customer's sentiment, building a community in motion that thinks for you in years and it means everything that's in between.
                            <br /><br />There is no one size fits all strategy, even for a company who has cracked ads will have to piviot with market sentiment and growth. We look forward to building a legacy and be sustainable in the process.
                            <br /><br /><b>Squeeze when juice is worth the squeeze.
                            <br />Juice is always worth the squeeze</b></p>
                    </div>
                </div>
            )
        }   
}

export default Slide1