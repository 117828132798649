import React from 'react'
import Dropdown from './Contact/Dropdown/Dropdown'
import Navbar from './Home/Navbar/Navbar'
import Mail from './Contact/BetweenMail/Mail'
import LogoText from './Contact/LogoText/LogoText'
import Contactbtn from './Contact/ContactButtons/Contactbtn'
import Socials from '../Components/About/Socials/Socials'
const Contact = () => {
  return (
    <>
      <div>
        <Navbar/>
        <Dropdown />
        <Mail/>
        <LogoText/>
        <Contactbtn/>
        <Socials />
      </div>
      <div style={{display:'none'}}>
        <h1>Advertising Agency Near Delhi, Mumbai, India</h1>
      </div>
    </>
  )
}

export default Contact