import React, {useEffect} from 'react'
import './PdfButton.css'
import Aos from 'aos'
import 'aos/dist/aos.css'

const PdfButton = () => {

  useEffect(() => {
    Aos.init({
        duration: 900
    })
  }, []);


  const linkToDrive = 'https://drive.google.com/file/d/1vPpmogQqKGTMGWHuqmJp03B4J0kdDVc7/view?usp=drive_link';
  return (
    <div className='pdf-button-container card' >
      <a className='pdf-button-link' href={linkToDrive} target='_blank' rel="noreferrer">
        <button className='pdf-button'>
          <p className='pdf-button-header'>Proposal</p>
          <p className='pdf-button-description'>Download Ek Idea Proposal PDF</p>
        </button>
      </a>
    </div>
  )
}

export default PdfButton