import React, { useEffect } from 'react'
import './Connect.css'
import email from '../../../Assets/email.png'
import instagram from '../../../Assets/instagram.png'
import linkedin from '../../../Assets/linkedin.png'
import discord from '../../../Assets/discord-mark-black.png'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom'
const Connect = () => {
    useEffect(() => {
        Aos.init({
            duration: 900
        })
    }, [])
    if (window.innerWidth > 500) {
        return (
            <>
                <div className='buttons card'>
                    <div className='left' data-aos="zoom-in">
                        <div className='buttons-text'><p>you are in India?</p>
                            <p>you can shoot or edit content?</p>
                            <p>you watch movies and say, "I can do that better"?</p>
                            <p>you have ideas for your favourite brand?</p>
                            <p>you have a portfolio to share with us?</p>
                            <p>you have ek idea?</p>
                        </div>
                        <button className='buttons-button'><Link to={"creatorcircle"}><p className='button-text'>Join the Creator Network</p></Link></button>
                    </div>
                    <div className='right' data-aos="zoom-in">
                        <div className='buttons-text'><p>you are growing a brand?</p>
                            <p>you want someone to make videos?</p>
                            <p>you want someone to make content?</p>
                            <p>you want to be the cool kid on the gram?</p>
                            <p>you need content ideas?</p>
                            <p>you have ek idea?</p>
                        </div>
                        <button className='buttons-button'><Link to={"/contact"}><p className='button-text'>Get in touch</p></Link></button>
                    </div>
                </div>
                <div className='icons' data-aos="zoom-in" data-aos-offset="-100">
                    <a href='https://www.instagram.com/ekidea.in/' target='_blank' rel="noreferrer"><img src={instagram} alt='instagram' className='icon' /></a>
                    <a href='https://discord.gg/pdQZVYg6m6' target='_blank' rel="noreferrer" ><img src={discord} alt='discord' className='icon' id='discord' /></a>
                    <a href='https://www.linkedin.com/company/ekidea/' target='_blank' rel="noreferrer"><img src={linkedin} alt='linkedin' className='icon' /></a>
                    <a href='mailto:connect@ekidea.in' ><img src={email} alt='email' className='icon' /> </a>
                </div>
            </>
        )
    }
    else {
        return (
            <>
                <div className='buttons card'>
                    <div className='left' >
                        <div className='buttons-text'><p>you are in India?</p>
                            <p>you can shoot or edit content?</p>
                            <p>you watch movies and say, "I can do that better"?</p>
                            <p>you have ideas for your favourite brand?</p>
                            <p>you have a portfolio to share with us?</p>
                            <p>you have ek idea?</p>
                        </div>
                        <button className='buttons-button'><Link to={"creatorcircle"}><p className='button-text'>Join the Creator Network</p></Link></button>
                    </div>
                    <div className='right' >
                        <div className='buttons-text'><p>you are growing a brand?</p>
                            <p>you want someone to make videos?</p>
                            <p>you want someone to make content?</p>
                            <p>you want to be the cool kid on the gram?</p>
                            <p>you need content ideas?</p>
                            <p>you have ek idea?</p>
                        </div>
                        <button className='buttons-button'><Link to={"/contact"}><p className='button-text'>Get in touch</p></Link></button>
                    </div>
                </div>
                <div className='icons' >
                    <a href='https://www.instagram.com/ekidea.in/' target='_blank' rel="noreferrer"><img src={instagram} alt='instagram' className='icon' /></a>
                    <a href='https://discord.gg/pdQZVYg6m6' target='_blank' rel="noreferrer" ><img src={discord} alt='discord' className='icon' id='discord' /></a>
                    <a href='https://www.linkedin.com/company/ekidea/' target='_blank' rel="noreferrer"><img src={linkedin} alt='linkedin' className='icon' /></a>
                    <a href='mailto:connect@ekidea.in' ><img src={email} alt='email' className='icon' /> </a>
                </div>
            </>
        )
    }
}

export default Connect