

import Default from './Creators/Default/Default';
import Navbar from './Home/Navbar/Navbar'



function CreatorCircle() {
  return (
    <>
    <div className="Creator">
      <Navbar />
      <Default />
    </div>
    <div style={{display:"none"}}>
        <h1>
            Ek Idea creator circle, network of freelance creative and marketing professionals, filmmakers, graphic designers, vfx artists, creative writers, photographers, marketers, a community to share, learn and grow together.
        </h1>
      </div>
    </>
  );
  }




export default CreatorCircle;
