import React, { useEffect } from 'react'
import email from '../../../Assets/email.png'
import instagram from '../../../Assets/instagram.png'
import linkedin from '../../../Assets/linkedin.png'
import discord from '../../../Assets/discord-mark-black.png'
import Aos from 'aos'
import 'aos/dist/aos.css'
const Socials = () => {
    useEffect(() => {
        Aos.init({
            duration: 900
        })
    }, []);
    if (window.innerWidth > 500) {
        return (
            <div className='icons' data-aos="zoom-in" data-aos-offset="-100">
                <a href='https://www.instagram.com/ekidea.in/' target='_blank' rel="noreferrer"><img src={instagram} alt='instagram' className='icon' /></a>
                <a href='https://discord.gg/pdQZVYg6m6' target='_blank' rel="noreferrer" ><img src={discord} alt='discord' className='icon' id='discord' /></a>
                <a href='https://www.linkedin.com/company/ekidea/' target='_blank' rel="noreferrer"><img src={linkedin} alt='linkedin' className='icon' /></a>
                <a href='mailto:connect@ekidea.in' ><img src={email} alt='email' className='icon' /> </a>
            </div>
        )
    }
    else {
        return (
            <div className='icons'>
                <a href='https://www.instagram.com/ekidea.in/' target='_blank' rel="noreferrer"><img src={instagram} alt='instagram' className='icon' /></a>
                <a href='https://discord.gg/pdQZVYg6m6' target='_blank' rel="noreferrer" ><img src={discord} alt='discord' className='icon' id='discord' /></a>
                <a href='https://www.linkedin.com/company/ekidea/' target='_blank' rel="noreferrer"><img src={linkedin} alt='linkedin' className='icon' /></a>
                <a href='mailto:connect@ekidea.in' ><img src={email} alt='email' className='icon' /> </a>
            </div>
        )
    }
}

export default Socials