import React from 'react'
import './Slide2.css'

const Slide2 = () => {

    return (
        <div className='slide-2'>
            <div className='page2-title page2-title-2'>
                <p>
                    We are as good as our ideas
                </p>
            </div>
            <div className='page2-text-container'>
                <p className='page2-description'>
                    Good ideas can reach millions by themself and average one need
                    millions in money to do same. Ideas are out there in abundance, everyone
                    has ideas.
                    <br /><br />All ideas when taken care of will develop into something wonderful,
                    Fresh Ideas are crucial to break out of the crowd.
                    <br /><br />At Ek idea we aspire to build and environment where Ideas can survive,
                    grow stronger and live to see the fame they deserve.
                    <br /><br />Content has insane leverage and all we need to unlock that is
                    ek solid idea.</p>
            </div>
        </div>
    )
}

export default Slide2