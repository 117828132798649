import React, {useEffect} from 'react'
import './Contactbtn.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom'

const Contactbtn = () => {

    useEffect(() => {
        Aos.init({
            duration: 900
        })
      }, []);
      
    return (
        <>
            <div className='contact-button' data-aos="zoom-out">
                    <button  className='contact-btn' >
                        <Link className='contact-button-link' to={"/creatorcircle"}>
                            <p className='contact-btn-header' data-aos="zoom-out">I would like to join the creator circle</p>
                        </Link>
                    </button>
                    <button className='contact-btn' >
                        <a className='contact-button-link' href='https://discord.gg/pdQZVYg6m6' target='_blank' rel="noreferrer">
                            <p className='contact-btn-header' data-aos="zoom-out">I would like to meet more creators and grow with them</p>
                        </a>
                    </button>
            </div>
            <div className='contact-greet'>
                <i className='greet-text'>Get in touch, stay in touch</i>
            </div>
        </>
    )
}

export default Contactbtn