import Bakery from "./Home/Bakery/Bakery"
import Brands from './Home/Brands/Brands'
import Connect from './Home/Connect/Connect'
import Founders from './Home/Founders/Founders'
import Gif from "./Home/Gif/Gif";
import Intro from './Home/Intro/Intro'
import Navbar from './Home/Navbar/Navbar'
import Services from "./Home/Services Slider/Services";
import './Home.css'


function MobileHome() {

    return (
    <>
      <div className="home" > 
      <section className="reel"><Navbar /></section>
      <section className="reel"><Intro /></section>
      <section className="reel"><Bakery /></section>
      <section className="services-container"><Services /></section>
      <section className="reel"><Gif /></section>
      <section className="reel"><Founders /></section>
      <section className="reel"><Brands /></section>
      <section className="reel"><Connect /></section>

      </div>
      <div style={{display:'none'}}>
        <h1>Digital content production agency in Delhi and Mumbai, India</h1>
      </div>
    </>
    );
  
}


export default MobileHome;
