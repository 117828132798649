import React, { useEffect, useState } from 'react'
import aneesh from '../../../Assets/Aneesh2 (1).png'
import ajinkya from '../../../Assets/Ajinkya.png'
import './Founders.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
const Founders = () => {
    useEffect(() => {
        Aos.init({
            duration: 800
        })
    }, [])
    const openInstagram = (name)=>{
       if(name==="Aneesh"){window.open('https://www.instagram.com/shotbyaneesh/', '_blank')}
       else if(name==="Ajinkya"){window.open('https://www.instagram.com/asla__barud/', '_blank')}
    }
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((current) => (current === 0 ? 1 : 0));
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className='founders card' >
                <div className='aneesh' data-aos="fade-left">
                <a href='https://www.instagram.com/shotbyaneesh/' target='_blank' rel="noreferrer"><img src={aneesh} alt='aneesh ji' className='founder-img' /></a>
                    <div className='aneesh-paras'>
                        <span className='description'>Five plus years into Advertising, writing, and directing Ad films for 45+ brands across industries.<br /><br /> With interest expanding beyond, to all ways of telling stories through Brands.</span>
                        <p className='name'>Aneesh Rathi <br />
                            <span className='position'>co-founder</span>
                        </p>
                    </div>
                </div>
                <hr />
                <div className='ajinkya' data-aos="fade-right">
                    <div className='ajinkya-paras'>
                        <p className='name'>Ajinkya Galav <br />
                            <span className='position'>co-founder</span>
                        </p>
                        <span className='description'>Producing and managing social media content for international brands with cultural competency through trusted media brands, New York.</span>
                    </div>
                    <a href='https://www.instagram.com/asla__barud/' target='_blank' rel="noreferrer"><img src={ajinkya} alt='ajinkya ji' className='founder-img' /></a>
                </div>
            </div>
            <div className='mobile-founders'>
                <div
                    className='founders-carousel'
                    style={{ transform: `translateX(-${activeIndex * 50}%)`}}
                >
                    <div className='mobile-aneesh' >
                        <img src={aneesh} alt='aneesh ji' className='mobile-founder-img' onClick={()=>openInstagram("Aneesh")} />
                        <div className='mobile-aneesh-paras'>
                            <span className='mobile-description'>Five plus years into Advertising, writing, and directing Ad films for 45+ brands across industries.<br /><br /> With interest expanding beyond, to all ways of telling stories through Brands.</span>
                            <p className='mobile-name'>Aneesh Rathi <br />
                                <span className='mobile-position'>co-founder</span>
                            </p>
                        </div>
                    </div>
                    <div className='mobile-ajinkya'>
                        <div className='mobile-ajinkya-paras'>
                            <p className='mobile-name'>Ajinkya Galav <br />
                                <span className='mobile-position'>co-founder</span>
                            </p>
                            <span className='mobile-description'>Producing and managing social media content for international brands with cultural competency through trusted media brands, New York.</span>
                        </div> 
                        <img src={ajinkya} alt='ajinkya ji' className='mobile-founder-img' onClick={()=>openInstagram("Ajinkya")}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Founders