import React ,{useEffect} from 'react'
import './Page1.css'
import pinkLogo from '../../../Assets/Logo for Deck 2.png'
import Aos from 'aos'
import 'aos/dist/aos.css'
const Page1 = () => {
    useEffect(() => {
        Aos.init({
            duration: 900
        })
    }, []);
    return (
        <div className='page1 card'>
            <div className='about-page1-p-1'>
                <img className="pink-logo" src={pinkLogo} alt='logo' data-aos="zoom-in" />
                <div className='about-page1-para-1' data-aos="zoom-in">
                    <p><i className='ek-idea-name'>Ek Idea</i> is a content producing agency. We create digital videos, photographs and all other assets that are key to give a character to this invisible being called "Brand"</p>
                </div>
            </div>
            <p className='about-page1-para-2' data-aos="zoom-in">Founded by filmmakers, powered by a network of 100+ content professionals across the country, Ek Idea makes sure everything we deliver is benchmarked as the best.</p>
            <p data-aos="zoom-in">Ek idea is working towards enabling creative talent across the country to present, create and perform for brands that serves today's market. Ek Idea not just brings in buffet of talent for brands but also acts as shock absorber for brands to heal from wounds of working fresh freelancers.</p>
            <p data-aos="zoom-in">We envision a content ecosystem where talent with best idea and finest skills to produce gets to work on the project from brands who believe in their idea.</p>
        </div>
    )
}

export default Page1