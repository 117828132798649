import React, { useEffect } from 'react'
import "./Gif.css"
import video from '../../../Assets/Wide gif.mp4'
import midgif from '../../../Assets/Mobile gif.mp4'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Gif = () => {
  useEffect(()=>{
    Aos.init({
      duration:800
    })
  },[])
  if(window.innerWidth>=500){
  return (
    <div className='gif card' >
        <video className='background' autoPlay loop muted onContextMenu={(e) => e.preventDefault()} data-aos="fade-in" >
            <source src={video} type='video/mp4' />
        </video>
    </div>
  )}
  else{
    return (
      <div className='gif card'>
          <video className='background' autoPlay loop muted onContextMenu={(e) => e.preventDefault()} draggable="false">
              <source src={midgif} type='video/mp4' />
          </video>
          {/* <img className='background' src={midgif} alt='gif' onContextMenu="return false;" draggable="false"/> */}
      </div>
    )
  }
}

export default Gif