import React from 'react'
import './Slide5.css'
const Slide5 = () => {
    return (
        <div className='slide-5'>
            <div className='page2-title page2-title-5'>
                <p>
                    Ads are annoying, our ads shoudn't be
                </p>
            </div>
            <div className='page2-text-container'>
                <p className='page2-description'>
                    There is a popular saying,
                    "only people interested in Ads are people who make Ads".
                    <br /><br />The world isn't waiting for more of the same, once in a while we see
                    ads that intrigue us, they stay with us, we mention them and they
                    change the way we view the world.
                    <br /><br />Here we have the opportunity, the strength and the minds to make
                    something that will be our legacy.
                </p>
            </div>
        </div>
    )
}

export default Slide5