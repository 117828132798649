import React, { useState ,useEffect} from 'react'
import './LogoText.css'
import blue_logo from '../../../Assets/blue_logo.png'
import yellow_logo from '../../../Assets/yellow_logo.png'
import pink_logo from '../../../Assets/pink_logo.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

const LogoText = () => {

    useEffect(() => {
        Aos.init({
            duration: 900
        })
      }, []);
    const [displayText, setDisplayText] = useState('default')

    const texts = {
        default: `What separates us from traditional social management agencies is our connection with today's trends and guts to put out bold work enabled by young talent from diverse backgrounds, people who eat keyboard warriors for breakfast.
        
        We produce everything from hero films to social snacks, the best producers and young creatives powered by skilled crew on our speed dial.make sure we put up a good show for our audiences. young creative minds from across the lands of this country make sure we represent right.
       
        we tap into the network, with focus groups and other activities to help clients gain key insights. What this community truly enables is creating the content in a way that is authentic to viewers.`,

        pink: `At Ek Idea Creative Agency our body of creatives with bold ideas and fresh air develop strategy and plans to connect the brand with right audiences, Ek idea being backed by young creative minds makes sure the language we speak is today’s social language.
            
        Creating new work often means working with new minds, new opinions and new demographics not having a POV that is manufactured and made generic.
            
        we blend in audience sentiment, meaningful insight and industry data to create what stands out and stays along.
        `,
        blue: `Ek Idea Creator Circle is not just a database but rather a community of content professionals who are freelancers and moonlighters across the industry, 

        this community aims to provide a platform to young creative minds to network, learn and grow faster. and a pool of diverse talent for brands enabling a lot of new possibilities of new creative ways to reach the untapped.

        At Creator Circle we aspire to develop the next generation of storytellers for this country.
        `,
        yellow: `Ek Idea Content Studio is video/film production house that utilizes the best talent from the industry to produce everything from the hero commercial to social nuggets.

        This Includes, Directors, DPs, Photographers, Photographers, Music producers, Set Designers and the whole shebang.

        With an aim to craft stories with humans in motion visualised in some of the most incredible places. stories that our viewers can play in.
        `
    }

    const handleMouseEnter = (color) => {
        setDisplayText(color)
    }

    const handleMouseLeave = () => {
        setDisplayText('default')
    }
    const formatText = (text) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                {index < text.split('\n').length - 1 && <br />}
            </React.Fragment>
        ));
    }


    if (window.innerWidth > 550) {
        return (
            <div className='logoText'>
                <div className='logo-wall'>
                    <div
                        className='logo-wall-child logo-wall-pink'
                        onMouseEnter={() => handleMouseEnter('pink')}
                        onMouseLeave={handleMouseLeave} data-aos="zoom-in"
                    >
                        <img src={pink_logo} className='logo-wall-img' alt='logo' />
                        <p className='logo-wall-text' data-aos="zoom-in">Creative Agency</p>
                    </div>
                    <div
                        className='logo-wall-child logo-wall-blue'
                        onMouseEnter={() => handleMouseEnter('blue')}
                        onMouseLeave={handleMouseLeave} data-aos="zoom-in"
                    >
                        <img src={blue_logo} className='logo-wall-img' alt='logo' />
                        <p className='logo-wall-text' data-aos="zoom-in">Creator Circle</p>
                    </div>
                    <div
                        className='logo-wall-child logo-wall-yellow'
                        onMouseEnter={() => handleMouseEnter('yellow')}
                        onMouseLeave={handleMouseLeave} data-aos="zoom-in"
                    >
                        <img src={yellow_logo} className='logo-wall-img' alt='logo' />
                        <p className='logo-wall-text' data-aos="zoom-in">Content Studio</p>
                    </div>
                </div>
                <div className='logo-text' data-aos="zoom-in">
                    <p className='logo-text-para' data-aos='zoom-in' >
                        {formatText(texts[displayText])}
                    </p>
                </div>
            </div>
        )
    }
    else {

        return (
            <div className='logoText'>
                <div className='logo-wall'>
                    <div
                        className='logo-wall-child logo-wall-pink'
                    >
                        <img src={pink_logo} className='logo-wall-img' alt='logo' />
                        <p className='logo-wall-text'>Creative Agency</p>
                    </div>
                    <div
                        className='logo-wall-child logo-wall-blue'

                    >
                        <img src={blue_logo} className='logo-wall-img' alt='logo' />
                        <p className='logo-wall-text'>Creator Circle</p>
                    </div>
                    <div
                        className='logo-wall-child logo-wall-yellow'

                    >
                        <img src={yellow_logo} className='logo-wall-img' alt='logo' />
                        <p className='logo-wall-text'>Content Studio</p>
                    </div>
                </div>
                <div className='mobile-logo-text'>
                    <p className='mobile-logo-text-para'>
                        What separates us from traditional <b>social management</b> agencies is our connection with today's trends and guts to put out bold work enabled by young talent from diverse backgrounds, people who eat keyboard warriors for breakfast.
                        <br /><br />We produce everything from hero films to social snacks, the best producers and young creatives powered by skilled crew on our speed dial.make sure we put up a good show for our audiences. young creative minds from across the lands of this country make sure we <b>represent right</b>.
                        <br /><br />We tap into the network, with focus groups and other activities to help clients <b>gain key insights</b>. What this community truly enables is creating the content in a way that is authentic to viewers.
                    </p>
                </div>
            </div>
        );

    }
}

export default LogoText