import React, { useRef, useState,useEffect } from 'react';
import arrow from '../../../Assets/dropdown1.png';
import arrow2 from '../../../Assets/arrow2.png';
import emailjs from '@emailjs/browser';
import './Dropdown.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
const Dropdown = () => {

  useEffect(() => {
    Aos.init({
        duration: 900
    })
  }, []);

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [selected1, setSelected1] = useState('Brand manager');
  const [selected2, setSelected2] = useState('Video production');
  const [isSelected1, setIsSelected1] = useState(false);
  const [isSelected2, setIsSelected2] = useState(false);
  const [showSecondForm, setShowSecondForm] = useState(false);
  const [isArrowClicked, setIsArrowClicked] = useState(false);

  const toggleDropdown1 = () => {
    setIsOpen2(false);
    setIsOpen1(!isOpen1);
  }
  const toggleDropdown2 = () => {
    setIsOpen1(false);
    setIsOpen2(!isOpen2);
  }

  const handleSelect1 = (option) => {
    setSelected1(option);
    setIsOpen1(false);
    setIsSelected1(true);
  };

  const handleSelect2 = (option) => {
    setSelected2(option);
    setIsOpen2(false);
    setIsSelected2(true);
  };
  
  const secondFormShower=()=>{
    setShowSecondForm(!showSecondForm);
    setIsArrowClicked(!isArrowClicked);
    if (!showSecondForm) {
      window.scrollTo({
        top: 500,
        behavior: 'smooth'
      });
    }
  }

  const form = useRef();
  
  const sendEmail = (e) => {
    const formElement = document.querySelector(".contactForm")
    e.preventDefault();
    //validation:
    let isNameValid = validateName(name);
    let isCompanyValid = validateCompany(company);
    let isEmailValid = validateEmail(email);
    let isNotesValid = validateNotes(notes);
    let isForMessageValid = validateForMessage(message);
    if (!isForMessageValid || !isNameValid || !isCompanyValid || !isEmailValid || !isNotesValid) {
        return;
    }

    const formData = new FormData(formElement)
    fetch("https://script.google.com/macros/s/AKfycbyTjAiDwYJWg0WsvZGvwgDXOmN09KbEqPRJvsPvDGE1vnUnA6bjvT-qjnto8zaBmvY/exec",{
      method:"POST",
      body:formData
    }).catch((error)=>console.log(error));
    emailjs
      .sendForm('service_26zvrji', 'template_gwgkk11', form.current, {
        publicKey: 'xCgkcEXT-L-oFDY20',
      })
      .then(
        () => {
          alert('Message sent successfully!')
          e.target.reset()
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  
  const[message, setMessage] = useState('');
  const [forMessageError, setForMessageError] = useState("");
  const handleInputChange = (event)=>{
    const newVal = event.target.value;
    setMessage(newVal);
    validateForMessage(newVal);
  };

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const validateName = (name) => {
      if (name.trim() === '') {
          setNameError('Please enter a valid name');
          return false;
      }

      if (!/^[a-zA-Z\s]+$/.test(name)) {
          setNameError('Please enter a valid name');
          return false;
      }

      if (name.length < 3 || name.length > 50) {
          setNameError('Please enter a valid name');
          return false;
      }

      setNameError('');
      return true;
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    validateName(newName);
  };

  const [companyError, setCompanyError] = useState("");
  const [company, setCompany] = useState('');
  const validateCompany = (company) => {
      if (company.trim() === '') {
          setCompanyError('Please enter a valid company name');
          return false;
      }

      if (!/^[a-zA-Z\s]+$/.test(company)) {
          setCompanyError('Please enter a valid company name');
          return false;
      }

      if (company.length < 2 || company.length > 50) {
          setCompanyError('Please enter a valid company name');
          return false;
      }

      setCompanyError('');
      return true;
  };

  const handleCompanyChange = (e) => {
    const newVal = e.target.value;
    setCompany(newVal);
    validateCompany(newVal);
  };

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const validateEmail = (email) => {
      if (email.trim() === '') {
          setEmailError('Email is required');
          return false;
      }

      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
          setEmailError('Please enter a valid email address');
          return false;
      }

      setEmailError('');
      return true;
  };

  const handleEmailChange = (e) => {
    const newVal = e.target.value;
    setEmail(newVal);
    validateEmail(newVal);
  };

  const [notes, setNotes] = useState('');
  const [notesError, setNotesError] = useState('');
  const validateNotes = (notes) => {
      if (notes.trim() === '') {
          setNotesError('Please enter a message');
          return false;
      }

      setNotesError('');
      return true;
  };

  const handleNotesChange = (e) => {
    const newVal = e.target.value;
    setNotes(newVal);
    validateNotes(newVal);
  };


  const validateForMessage = (val) => {
    let isValid = true;
    if (val === "") {
      setForMessageError("Please enter a message");
      isValid = false;
    } else {
      setForMessageError("");
    }
    return isValid;
  }
  const handleFirstFormSubmit = ()=>{
      if(validateForMessage(message)){
         secondFormShower();
      }
  }

if(window.innerWidth<=550){
  return (
    <>
      <div className='form'>

        
        <div className='form-position'>
          <p className='form-text'>Hello, I am a</p>
          <div className='dropdown'>
            <div onClick={toggleDropdown1} className='dropdown-clicker'>
              <p  className={`dropdown-text ${isSelected1 ? 'dropdown-rotated-text' : ''}`}>{selected1}</p>
              <img
                src={arrow}
                alt='arrow'
                className={`arrow ${isOpen1 ? 'arrow-rotated' : ''}`}
              />
            </div>
            {isOpen1 && (
              <div className='dropdown-hidden'>
                {['Brand Manager', 'Business Owner', 'Producer', 'Executive/Corporate Manager', 'Other'].map((option) => (
                  <p key={option} className='dropdown-text dropdown-text-1' onClick={() => handleSelect1(option)}>
                    {option}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className='form-work'>
          <p className='form-text'>Who needs</p>
          <div className='dropdown'>
            <div onClick={toggleDropdown2} className='dropdown-clicker'>
              <p  className={`dropdown-text ${isSelected2 ?'dropdown-rotated-text' : ''}`}>{selected2}</p>
              <img
                src={arrow}
                alt='arrow'
                className={`arrow ${isOpen2 ? 'arrow-rotated' : ''}`}
              />
            </div>
            {isOpen2 && (
              <div className='dropdown-hidden'>
                {['Social Management', 'Content Shoot', 'Video/Film Production', 'Other'].map((option) => (
                  <p key={option} className='dropdown-text dropdown-text-2' onClick={() => handleSelect2(option)}>
                    {option}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
        <p className='form-text'>for</p>
        <div className='form-text-box'>
        {forMessageError && <div className='error'><p className='error-message'>Please complete the form</p></div>}

          <textarea className='mobile-form-text-input' name='for-message' rows="10" onChange={handleInputChange} style={{border:forMessageError?"1px solid red":"none"}} />
          <img src={arrow2} className='mobile-arrow-2' style={{transform:isArrowClicked?'rotate(90deg)':'rotate(0deg)'}} alt='arrow' onClick={handleFirstFormSubmit} />
        </div>
      </div>

      <div className='second-form' style={{display:(showSecondForm?"flex":"none")}}>

        <form className='contactForm' ref={form} onSubmit={sendEmail}>
          <input type='hidden' name='position' value={selected1} />
          <input type='hidden' name='requirement' value={selected2} />
          <input type='hidden' name='forMessage' value={message}/>
          <input className='form-name' type='text'placeholder='Full Name' style={{border:nameError?"1px solid red":"none"}} name='name' onChange={handleNameChange} />
          {nameError && <p className='error-message'>{nameError}</p>}
          <input className='form-name' type='text' placeholder='Company' name='company' style={{border:companyError?"1px solid red":"none"}}  onChange={handleCompanyChange} />
          {companyError && <p className='error-message'>{companyError}</p>}

          <input className='email' type='email' placeholder='E-mail' name='email' style={{border:emailError?"1px solid red":"none"}} onChange={handleEmailChange} />
          {emailError && <p className='error-message'>{emailError}</p>}

            <div className='message-n-submit'>
              <textarea className='msg' name='message' rows="5" placeholder='Notes' style={{border:notesError?"1px solid red":"none"}} onChange={handleNotesChange} />
              {notesError && <p className='error-message'>{notesError}</p>}
              
              <button className='submit' type='submit' value='Send'> <img src={arrow2} className='submit-arrow' alt='arrow' /></button>
            </div>
        </form>
      </div>
    </>
  );
}
else{  
  return (
    <>
      <div className='form' data-aos="zoom-in">

        <p  className='form-text' >Hello,</p>
        <div className='form-position'>
          <p   className='form-text' >I am a</p>
          <div  className='dropdown' >
            <div onClick={toggleDropdown1} className='dropdown-clicker'>
              <p  className={`dropdown-text ${isSelected1 ? 'dropdown-rotated-text' : ''}`}>{selected1}</p>
              <img
                src={arrow}
                alt='arrow'
                className={`arrow ${isOpen1 ? 'arrow-rotated' : ''}`}
              />
            </div>
            {isOpen1 && (
              <div className='dropdown-hidden'>
                {['Brand Manager', 'Business Owner', 'Producer', 'Executive/Corporate Manager', 'Other'].map((option) => (
                  <p key={option} className='dropdown-text dropdown-text-1' onClick={() => handleSelect1(option)}>
                    {option}
                  </p>
                ))}
              </div>
            )}
          </div>

        </div>
        <div className='form-work'>
          <p  className='form-text' >Who need</p>
          <div   className='dropdown'>
            <div onClick={toggleDropdown2} className='dropdown-clicker'>
              <p  className={`dropdown-text ${isSelected2 ?'dropdown-rotated-text' : ''}`}>{selected2}</p>
              <img
                src={arrow}
                alt='arrow'
                className={`arrow ${isOpen2 ? 'arrow-rotated' : ''}`}
              />
            </div>
            {isOpen2 && (
              <div className='dropdown-hidden'>
                {['Social Management', 'Content Shoot', 'Video/Film Production', 'Other'].map((option) => (
                  <p key={option} className='dropdown-text dropdown-text-2' onClick={() => handleSelect2(option)}>
                    {option}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className='form-text-box'>
          <p   className='form-text' >for</p>
          <textarea  className='form-text-input' name='for-message' rows="10" onChange={handleInputChange}  />
          <img src={arrow2} className={`arrow-2 ${isArrowClicked ? 'arrow-2-rotated' : ''}`} alt='arrow' onClick={handleFirstFormSubmit} />
        </div>
        {forMessageError && <div className='error'><p className='error-message'>Please complete the form</p></div>}
        
      </div>
      <div  className='second-form' style={{display:(showSecondForm?"flex":"none")}} >

      <form className='contactForm' ref={form} onSubmit={sendEmail}>
          <input type='hidden' name='position' value={selected1} />
          <input type='hidden' name='requirement' value={selected2} />
          <input type='hidden' name='forMessage' value={message}/>
          <input className='form-name' type='text' placeholder='Full Name' name='name' style={{border:nameError?"1px solid red":"none"}} onChange={handleNameChange} />
          {nameError && <p className='error-message'>{nameError}</p>}
          <input className='form-name' type='text' placeholder='Company' name='company' style={{border:companyError?"1px solid red":"none"}} onChange={handleCompanyChange} />
          {companyError && <p className='error-message'>{companyError}</p>}

          <input className='email' type='email' placeholder='E-mail' name='email' style={{border:emailError?"1px solid red":"none"}} onChange={handleEmailChange} />
          {emailError && <p className='error-message'>{emailError}</p>}
            
            <div className='message-n-submit'>
              <div>
                <textarea className='msg' name='message' rows="5" placeholder='Notes' style={{border:notesError?"1px solid red":"none"}} onChange={handleNotesChange} />
                <div className='error'>{notesError && <p className='error-message'>{notesError}</p>}</div>

              </div>
              <button className='submit' type='submit' value='Send'> <img src={arrow2} className='submit-arrow' alt='arrow'  /></button>
            </div>
            
        </form>
      </div>
    </>
  );}
};

export default Dropdown;