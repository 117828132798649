import React from 'react'
import './Slide4.css'
const Slide4 = () => {
    return (
        <div className='slide-4'>
            <div className='page2-title page2-title-4'>
                <p>
                    Pop culture is part of our job
                </p>
            </div>
            <div className='page2-text-container'>
                <p className='page2-description'>
                    Standardising processes, automating tasks, leads to efficency, but when
                    this is applied to content, it can come across as heartless. if we want
                    people's attention, we'll have to earn it.
                    <br /><br />Treat your audience like your girlfriend, being human is essential to
                    build lasting relations.
                    <br /><br />Internet sentiment changes fast what's cool today can be annoying
                    and fade away next week, We can not be talking about a world that
                    doesn't exist, pop culture moves fast like fashion and it's our job at
                    Ek Idea not just to keep up with it but also be the drivers of this change.
                </p>
            </div>
        </div>
    )
}

export default Slide4