import React, {useEffect } from 'react'
import './Mail.css'
import Aos from 'aos';
import 'aos/dist/aos.css';

const Mail = () => {
  useEffect(() => {
    Aos.init({
        duration: 900
    })
  }, []);

  const email = 'hello@ekidea.in';
  const width = window.innerWidth;
  
  return (
    <div className="between-mail">
      <div className='mail'>
        <p className='mail-text-header' data-aos="fade-left">Talk to us</p>
        {width >500 && <div className='mail-container' data-aos="fade-right">
          <a className='mail-text' href='mailto:hello@ekidea.in'>{email}</a>
        </div>}
        {width <=500 && <div className='mail-container' data-aos="zoom-out">
          <a className='mail-text' href='mailto:hello@ekidea.in'>{email}</a>
        </div>}
      </div>
    </div>
  )
}

export default Mail