import React from 'react'
import "./Services.css"

const Services = () => {
  const services = " Brand Films • Lifestyle Videos • Corporate Videos • Product Films • Graphic Design • Product Photographs • Fashion Photographs • Lifestyle Photographs • Social Media • Brand Positioning • Memes • ";
  const width = window.innerWidth;
  return (
    <div className='services'>
      <div className='services-container'>
        <p className={`services-p ${width<=550 ? 'mobile' : ''}`}>{services}</p>
        <p className={`services-p ${width<=550 ? 'mobile' : ''}`}>{services}</p>
      </div>
    </div>
  )
}

export default Services