import React from 'react'
import './Slide3.css'

const Slide3 = () => {
    return (
        <div className='slide-3'>
            <div className='page2-title page2-title-3'>
                <p>
                    Put the same passion in everything
                </p>
            </div>
            <div className='page2-text-container'>
                <p className='page2-description'>
                    When a stadium roars it's not just for the game it's response to the
                    display of passion by these humans on field, people crave authenticity,
                    they look for fire in our creations, the unwavering belief in what we offer.
                    <br /><br />Luxury brands make sure their product exists in a world that is as
                    perfect and intricately crafted as it's products.
                    <br /><br />They go all out to make sure that audio visual experience of watching
                    this film reflects the experience they offer through their product or
                    service, similarly bad content may signal bad product.
                </p>
            </div>
        </div>
    )
}

export default Slide3