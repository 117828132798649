import React, { useEffect, useState } from 'react'
import './Page2.css'
import Slide1 from './Slide1/Slide1'
import Slide2 from './Slide2/Slide2'
import Slide3 from './Slide3/Slide3'
import Slide4 from './Slide4/Slide4'
import Slide5 from './Slide5/Slide5'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Page2 = () => {
  useEffect(() => {
    Aos.init({
      duration: 900
    })
  }, []);
  
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % 5);
    }, 6125);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="page2 card">
      <div className='carousel'>
        <div className="carousel-inner" style={{ transform: `translateX(-${currentSlide * 20}%)` }}>
          <Slide5 />
          <Slide4 />
          <Slide3 />
          <Slide2 />
          <Slide1 />
        </div>
        <div className='carousel-dots'>
          <ul className='heroDots'>
            <li onClick={()=>setCurrentSlide(0)} className={currentSlide === 0 ? "colored-dot" : "dot"}></li>
            <li onClick={()=>setCurrentSlide(1)} className={currentSlide === 1 ? "colored-dot" : "dot"}></li>
            <li onClick={()=>setCurrentSlide(2)} className={currentSlide === 2 ? "colored-dot" : "dot"}></li>
            <li onClick={()=>setCurrentSlide(3)} className={currentSlide === 3 ? "colored-dot" : "dot"}></li>
            <li onClick={()=>setCurrentSlide(4)} className={currentSlide === 4 ? "colored-dot" : "dot"}></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Page2