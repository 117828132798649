import React from 'react'
import './BrandCard.css'
const BrandCard = ({index, source}) => {
  return (
    <div className='brand-card'>
        <img key={index} src={source} alt='brand logo' />
    </div>
  )
}

export default BrandCard