import Bakery from "./Home/Bakery/Bakery"
import Brands from './Home/Brands/Brands'
import Connect from './Home/Connect/Connect'
import Founders from './Home/Founders/Founders'
import Gif from "./Home/Gif/Gif";
import Intro from './Home/Intro/Intro'
import Navbar from './Home/Navbar/Navbar'
import Services from "./Home/Services Slider/Services";


function Home() {
  return (
    <>
      <div className="Home">
      <Navbar />
      <Intro />
      <Gif />
      <Services />
      <Bakery />
      <Brands />
      <Founders />
      <Connect />
      </div>
      <div style={{display:'none'}}>
        <h1>Digital content production agency in Delhi and Mumbai, India</h1>
      </div>
    </>
  );
  }




export default Home;
